import React, { useState, useEffect } from 'react'
import WaitScreen from '../../components/WaitScreen'
import './products.scss'
import CurrencyFormatter from '../../components/CurrencyFormatter'
import useLocalStorageState from 'use-local-storage-state'
import { Link } from 'react-router-dom'

function Products() {
  // const API_URL = 'https://dummyjson.com/products'
  const API_URL = '/products/products.json'
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [error, setError] = useState(false)
  const [cart, setCart] = useLocalStorageState('cart', {})
  // const [cart, setCart] = useState('cart', {})
  useEffect(() => {
    fetchData(API_URL)
  }, [])

  async function fetchData(url) {
    try {
      const response = await fetch(url)
      if (response.ok) {
        const data = await response.json()
        setProducts(data.products)
        setIsLoading(false)
      } else {
        setError(true)
        setIsLoading(false)
      }
    } catch (error) {
      setError(true)
      setIsLoading(false)
    }
  }
  const addToCart = (product) => {
    product.quantity = 1

    setCart((prevCart) => ({
      ...prevCart,
      [product.id]: product
    }))
  }
  const isInCart = (productId) => Object.keys(cart || {}).includes(productId.toString())

  if (error) {
    return <h5 className='error'>An error occurred when fetching data. Please check the API and try again.</h5>
  }

  if (isLoading) {
    return <WaitScreen />
  }

  return (
    <section className='productPage'>
      <div className='heading'>
        <h3>Products</h3>
      </div>
      <div className='container'>
        {products.map((product) => (
          <div className='product' key={product.id}>
            <Link to={`/products/${product.id}`}>
              <img src={product.thumbnail} alt={product.title} />
              <h5>{product.title}</h5>
            </Link>

            <p>
              Price: <CurrencyFormatter amount={product.price} />
            </p>
            <button disabled={isInCart(product.id)} onClick={() => addToCart(product)}>
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </section>
  )
}
export default Products
