import React, { useEffect, useState } from 'react'
import CarouselComponent from '../../components/Carousel'
import MainCarouselComponent from '../../components/MainCarousel'
import SubCaption from '../../components/Subcaption'
import WaitScreen from '../../components/WaitScreen'

import './carousel.css'

const MainPage = () => {
  const API_URL = '/carousel/config.json'
  const [isLoading, setIsLoading] = useState(true)
  const [mainCarouselConfigArr, setMainCarouselConfigArr] = useState([])
  const [subCarouselConfigArr1, setSubCarouselConfigArr1] = useState([])
  const [subCarouselConfigArr2, setSubCarouselConfigArr2] = useState([])
  const [subCarouselConfigArr3, setSubCarouselConfigArr3] = useState([])
  const [error, setError] = useState(false)
  // const [cart, setCart] = useState('cart', {})
  useEffect(() => {
    fetchData(API_URL)
  }, [])

  async function fetchData(url) {
    try {
      const response = await fetch(url)
      if (response.ok) {
        const data = await response.json()
        setMainCarouselConfigArr(data.mainCarouselConfigArr)

        setSubCarouselConfigArr1(data.subCarouselConfigArr1)
        setSubCarouselConfigArr2(data.subCarouselConfigArr2)
        setSubCarouselConfigArr3(data.subCarouselConfigArr3)

        setIsLoading(false)
      } else {
        setError(true)
        setIsLoading(false)
      }
    } catch (error) {
      setError(true)
      setIsLoading(false)
    }
  }
  if (error) {
    return <h5 className='error'>An error occurred when fetching data. Please check the API and try again.</h5>
  }

  if (isLoading) {
    return <WaitScreen />
  }
  return (
    <>
      <div className='carousel-container'>
        <MainCarouselComponent carouselConfigs={mainCarouselConfigArr}></MainCarouselComponent>
      </div>
      <SubCaption />
      <div className='carousel-container2'>
        <CarouselComponent carouselConfigs={subCarouselConfigArr1}></CarouselComponent>
        <CarouselComponent carouselConfigs={subCarouselConfigArr2}></CarouselComponent>
        <CarouselComponent carouselConfigs={subCarouselConfigArr3}></CarouselComponent>
      </div>
    </>
  )
}
export default MainPage
