export async function callDirectPost(baseUrl, endPoint, data) {
  try {
    const res = await fetch(`${baseUrl}${endPoint}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: data
    }).then((res) => res.json())
    return res
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}
