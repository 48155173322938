import styled from 'styled-components'

export const Box = styled.div`
  color: white;

  margin-top: 10px;
  padding-top: 10 px;
  background: black;
  position: relative;
  bottom: 0;
  width: 100%;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`
export const Lin = styled.a`
  color: white;
`

export const Social = styled.div`
  justify-content: center;
  text-align: center;
  @media (max-width: 767px) {
  }
`
