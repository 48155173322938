import React from 'react'
import NavBar from './components/navbar/navbar'
// import Cart from './components/cart/cart';
import Footer from './components/Footer'
import './App.css'
import MainPage from './container/MainPage'
import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom'
import Products from './container/products'
import Cart from './components/Cart'
import ProductDetails from './container/product_detail'
import MyStory from './container/Mystory'

function App() {
  return (
    <Router>
      <div className='App'>
        <div className='main-div'>
          <NavBar />
          <Routes>
            <Route path='/' exact element={<MainPage />} />
            <Route path='/products' element={<Products />} />
            <Route path='/myStory' element={<MyStory />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/products/:productId' element={<ProductDetails />} />
            <Route path='*' element={<Navigate to={'/'} />} />
          </Routes>

          <Footer />
        </div>
      </div>
    </Router>
  )
}

export default App
