import React from 'react'
import { BannerImage, Sliderdiv } from './MainCarouselStyle'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { rootPath } from '../../constant/constant'

const MainCarouselComponent = ({ carouselConfigs }) => {
  const screenType=window.innerWidth > 1024 ? 'desktop' : 'mobile'

  return (
    <Sliderdiv>
      <Carousel showThumbs={false} showStatus={false} autoPlay={true} interval={5000} infiniteLoop transitionTime={2000}>
        {carouselConfigs.map((config, index) => (
          <div key={index}>
            <BannerImage src={`${rootPath}/carousel/${screenType}/${config.imageName}`} alt={index} key={index} />
            <p className='legend'>{config.text}</p>
          </div>
        ))}
      </Carousel>
    </Sliderdiv>
  )
}
export default MainCarouselComponent
