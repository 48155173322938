import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import WaitScreen from '../../components/WaitScreen'
import { baseUrl } from '../../constant/constant'
import './product_detail.css'
import ProductDetailCrousel from '../../components/ProductDetailCarousel'
import useLocalStorageState from 'use-local-storage-state'
import CurrencyFormatter from '../../components/CurrencyFormatter'

function ProductDetails() {
  const { productId } = useParams(0)
  const API_URL = 'products/products.json'
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [thisProduct, setThisProduct] = useState({})
  const [imageConfig, setImageConfig] = useState([])
  const [cart, setCart] = useLocalStorageState('cart', {})
  const navigate = useNavigate()

  useEffect(() => {
    fetchData(`${baseUrl}${API_URL}`)
  })

  async function fetchData(url) {
    try {
      const response = await fetch(url)
      if (response.ok) {
        const data = await response.json()
        const thisProduct = data.products.find((prod) => prod.id === Number(productId))
        let imageConfigArr = thisProduct.images.map((result) => {
          return {
            imagePath: result,
            text: result.name
          }
        })
        setImageConfig(imageConfigArr)
        setThisProduct(thisProduct)
        setIsLoading(false)
      } else {
        setError(true)
        setIsLoading(false)
      }
    } catch (error) {
      setError(true)
      setIsLoading(false)
    }
  }
  const addToCart = (product) => {
    product.quantity = 1

    setCart((prevCart) => ({
      ...prevCart,
      [product.id]: product
    }))
  }
  const isInCart = (productId) => Object.keys(cart || {}).includes(productId.toString())
  const handleGoToCart = () => navigate('/cart')

  if (error) {
    return <h3 className='error'>An error occurred when fetching data. Please check the API and try again.</h3>
  } else if (isLoading) {
    return <WaitScreen />
  } else {
    return (
      <div className='product_detail'>
        <div>
          <ProductDetailCrousel carouselConfigs={imageConfig}></ProductDetailCrousel>
        </div>
        <div className='productdetail'>
          <h1>{thisProduct.title}</h1>
          <p>
            Price: <CurrencyFormatter amount={thisProduct.price} />
          </p>
          <p>{thisProduct.description}</p>
          <div className='button_container'>
            <button className='button_style' disabled={isInCart(thisProduct.id)} onClick={() => addToCart(thisProduct)}>
              Add to Cart
            </button>
            <button className='button_style' onClick={handleGoToCart}>
              Go to Cart
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductDetails
