import React from 'react'
import { BannerImage, Sliderdiv } from './ProductDetailCrousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const ProductDetailCrousel = ({ carouselConfigs }) => {
  return (
    <Sliderdiv>
      <Carousel showStatus={false}>
        {carouselConfigs.map((config, index) => (
          <div key={index}>
            <BannerImage src={config.imagePath} key={index} />
          </div>
        ))}
      </Carousel>
    </Sliderdiv>
  )
}
export default ProductDetailCrousel
