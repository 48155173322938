import React from 'react'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Box, Social, Lin } from './FooterStyles'
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit'

const Footer = () => {
  return (
    <Box>
      <h5
        style={{
          color: 'orange',
          textAlign: 'left',
          padding: '10px'
        }}
      >
        &copy; 2023 Technowood Industries
      </h5>
      <hr style={{ height: '' }}></hr>

      <ul>
        <li>
          <Lin href='#'>Our Story</Lin>
        </li>
        <li>
          <Lin href='#'> Products</Lin>
        </li>

        <li>
          <Lin href='#'>Contact Us</Lin>
        </li>
      </ul>
      <Social>
        <MDBBtn className='m-1' style={{ backgroundColor: '#3b5998' }} href='#'>
          <MDBIcon fab icon='facebook-f' />
        </MDBBtn>

        <MDBBtn className='m-1' style={{ backgroundColor: '#55acee' }} href='#'>
          <MDBIcon fab icon='twitter' />
        </MDBBtn>

        <MDBBtn className='m-1' style={{ backgroundColor: '#dd4b39' }} href='#'>
          <MDBIcon fab icon='google' />
        </MDBBtn>

        <MDBBtn className='m-1' style={{ backgroundColor: '#ac2bac' }} href='#'>
          <MDBIcon fab icon='instagram' />
        </MDBBtn>
      </Social>
    </Box>
  )
}
export default Footer
