import React from 'react'
import './order_popup.scss'

function OrderPopup({ closePopup, handleSubmit }) {
  return (
    <div className='popup-container'>
      <div className='popup-body'>
        <form onSubmit={handleSubmit}>
          <label htmlFor='fname'>First Name</label>
          <input type='text' id='fname' name='firstname' placeholder='Your name..' required />

          <label htmlFor='lname'>Last Name</label>
          <input type='text' id='lname' name='lastname' placeholder='Your last name..' required />

          <label htmlFor='e-mail'>e-mail</label>
          <input type='e-mail' id='email' placeholder='Email' name='email' required />

          <label htmlFor='tel'>phone</label>
          <input type='number' id='phone' placeholder='Phone Number' name='phone' maxLength='10' required />

          <label htmlFor='comments'>Comments</label>
          <textarea id='comments' name='comments' placeholder='Write suggestions..'></textarea>
          <div className={'button_container'}>
            <button onClick={closePopup}>Cancel</button>

            <input type='submit' value='Submit' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default OrderPopup
