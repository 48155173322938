import React, { useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import './Cart.scss'
import Quantifier from '../Quantifier'
import TotalPrice from '../TotalPrice'
import OrderPopup from '../OrderPopup/OrderPopup'
import { callDirectPost } from '../../util/HttpUtil'
import { baseUrl } from '../../constant/constant'
import WaitScreen from '../WaitScreen'

function Cart() {
  const [cart, setCart] = useLocalStorageState('cart', {})
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const handleRemoveProduct = (productId) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart }
      delete updatedCart[productId]
      return updatedCart
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    const inputs = e.target.elements
    const data = {}

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value
      }
    }
    let emailBody = generateEmailData(cart, data)
    data['message'] = emailBody
    callDirectPost(baseUrl, 'php/mailPHP.php', JSON.stringify(data))
      .then((response) => {
        if (response.ok) {
          setIsLoading(false)
          setOpen(false)
        } else {
          setIsLoading(false)
          setOpen(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setOpen(false)
      })
  }

  function generateEmailData(car, data) {
    let emailData = ''
    // Iterate through the object
    const singleRow = ({ id, title, price, quantity }) => `<tr><td>${id}</td><td>${title}</td><td>${price}</td><td>${quantity}</td></tr>`
    let cartIds = Object.keys(cart)

    cartIds.forEach((cartId) => {
      emailData = emailData.concat(' ', singleRow(cart[cartId]))
    })
    let emailBody = `
        <body>
        <span>
         Hi,<br/>
         ${data.firstname} (e-mail: ${data.email} , PhoneNumber: ${data.phone}) has placed Order for following
        </span>
        <table>
        <thead>
            <tr>
            <th>Id</th>
            <th>Title</th>
            <th>Price</th>
            <th>quantity</th>
        </thead>
        <tbody id="test">
             ${emailData}
        </tbody>
        </table>
        <span>TotalPrice : ${totalPrice}</span>
        <span>Comments : ${data.comments}</span>
        </body>
        `
    return emailBody
  }

  const handleUpdateQuantity = (productId, operation) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart }
      if (updatedCart[productId]) {
        if (operation === 'increase') {
          updatedCart[productId] = { ...updatedCart[productId], quantity: updatedCart[productId].quantity + 1 }
        } else {
          updatedCart[productId] = { ...updatedCart[productId], quantity: updatedCart[productId].quantity - 1 }
        }
      }
      return updatedCart
    })
  }

  const getProducts = () => Object.values(cart || {})

  const totalPrice = getProducts().reduce((accumulator, product) => accumulator + product.price * product.quantity, 0)

  if (isLoading) {
    return <WaitScreen />
  }

  return (
    <>
      <section className='cart'>
        <h1>Cart</h1>

        <div className='container'>
          {getProducts().map((product) => (
            <div className='product' key={product.id}>
              <img src={product.thumbnail} alt={product.title} />
              <h3>{product.title}</h3>
              <Quantifier
                removeProductCallback={() => handleRemoveProduct(product.id)}
                productId={product.id}
                handleUpdateQuantity={handleUpdateQuantity}
              />
            </div>
          ))}
        </div>
        <TotalPrice amount={totalPrice} />
        <button onClick={() => setOpen(true)}> e-mail Order</button>
      </section>
      {open ? <OrderPopup closePopup={() => setOpen(false)} handleSubmit={handleSubmit} /> : null}
    </>
  )
}

export default Cart
