import React from 'react'
import { BannerImage, Sliderdiv } from './CarouselStyle'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { rootPath } from '../../constant/constant'

const CarouselComponent = ({ carouselConfigs }) => {
  const screenType=window.innerWidth > 1024 ? 'desktop' : 'mobile'
  return (
    <Sliderdiv>
      <Carousel showStatus={false} showThumbs={false}>
        {carouselConfigs.map((config, index) => (
          <div key={index}>
            <BannerImage src={`${rootPath}/carousel/${screenType}/${config.imageName}`} alt={index} />
            <p className='legend'>{screenType}-{config.text}</p>
          </div>
        ))}
      </Carousel>
    </Sliderdiv>
  )
}
export default CarouselComponent
