import React from 'react'
import { rootPath } from '../../constant/constant'
import { Link } from 'react-router-dom'
import cartLogo from '../../assets/shopping-cart.svg'
import useLocalStorageState from 'use-local-storage-state'
// import CartWidget from "../CartWidget";
import './navbar.css'


function NavBar() {
  const [cart] = useLocalStorageState('cart', {})

  const productsCount = Object.keys(cart || {}).length
  
  return (
    <nav className='navbar'>
      <div className='logo'>
        <img src={rootPath + '/images/technowoodlogo.png'} alt='' />
      </div>

      <ul className='nav-links'>
        <input type='checkbox' id='checkbox_toggle' />
        <label htmlFor='checkbox_toggle' className='hamburger'>
          &#9776;
        </label>

        <div className='menu'>
          <li>
            <a href='/'>Home</a>
          </li>
          <li>
          <Link className='NavLink' to='/myStory'>Our story</Link>
          </li>
          <li>
            {/* <a href="/products">Products</a> */}
            <Link className='NavLink' to='/products' >
              Products
            </Link>
          </li>

          {/* <li class="services">
            <a href="/">Services</a>

            <ul class="dropdown">
              <li><a href="/">Joineries</a></li>
              <li><a href="/">Doors & Windows</a></li>
              <li><a href="/">Interiors</a></li>
            </ul>

          </li>

          <li class="services"><a href="/">Products</a>
            <ul class="dropdown">
              <li><a href="/">Kitchen Ware</a></li>
              <li><a href="/">Home Decor</a></li>
              <li><a href="/">Furnitures</a></li>
            </ul>
          </li> */}
          <li>
            <a href='/'>Connect us</a>
          </li>
          <li>
            <Link className='NavLink' to='/cart' activestyle>
              {/* <a href="/cart"> */}
              <div className='cartwidget'>
                <img src={cartLogo} className='logo' alt='Shopping Cart Application' />
                <span className='productsCount'>{productsCount}</span>

                {/* <div>
                  <CartWidget productsCount={productsCount} />
                </div> */}
              </div>
              {/* </a> */}
            </Link>
          </li>
        </div>
      </ul>
    </nav>
  )
}

export default NavBar
