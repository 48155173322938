import React from 'react'
import './CurrencyFormatter.css'
const CurrencyFormatter = ({ amount }) => {
  const formattedAmount = amount.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR'
  })

  return <span className='currency'>{formattedAmount}</span>
}
export default CurrencyFormatter
