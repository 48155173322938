import React from 'react'
// import { LoadingMessage } from "./WaitScreenStyle";
import './Loader.css'

const WaitScreen = () => {
  return (
    <>
      {/* <LoadingMessage>Loading.... coming soon</LoadingMessage> */}
      <div id='loading' className='loading'>
        <div id='loading-text' className='loading-text'>
          Loading...
        </div>
        <div id='loading-circle' className='loading-circle'></div>
      </div>
    </>
  )
}

export default WaitScreen
