import React from 'react';

const MyStory = () => {
    return (
        <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <div  style={{textAlign:'center', margin:'40px', fontFamily: 'Arial, sans-serif',fontWeight:'bold', color:'brown', fontSize:'18px'}}>
                <strong>"Elevate your spaces to grandeur, creating your furniture ally with a tradition of enduring beauty and unmatched quality."</strong>
                </div>
            <h1 style={{ color: '#333' }}>ABOUT US</h1>
            <p>
                Cultivating a legacy since 1973, Techno Wood Industries has established itself as a cornerstone in wood craftsmanship. Originating as a sawmill under the visionary leadership of the Late Sri TC Halthaf Mammy, our evolution into a premier wholesale dealer of indigenous and imported wood across Kerala is a testament to our unwavering dedication to excellence. With each passing year, our team has cultivated an extraordinary level of expertise, honing skills, and fostering creativity to transform visions into tangible realities. Collaborating with skilled and innovative carpenters, we possess the capability to bring your bespoke dreams to life with the timeless allure of wood.
            </p>
            <p>
                Leveraging modern methodologies and the prowess of highly skilled craftsmen, we guarantee the delivery of products that surpass expectations in both quality and affordability, all while adhering to stringent timelines. At Techno Wood Industries, we don't just craft wood; we curate enduring experiences.
            </p>
            <h6 style={{ color: '#333' }}><strong>OUR VISION</strong></h6>
            <p>
                "Our vision is to excel in wood craftsmanship, setting the industry standard for innovation and uncompromising quality, while simultaneously striving for sustainable growth and global recognition."
            </p>
            <h6 style={{ color: '#333' }}><strong>OUR MISSION</strong></h6>
            <p>
            "At Techno Wood Industries, our mission is to tirelessly pursue perfection in every facet of our
craft, delivering unparalleled products and services that exceed expectations, while enhancing
spaces with a blend of elegance and functionality."
            </p>
            <div style={{textAlign:'center', margin:'40px', fontFamily: 'Arial, sans-serif',fontWeight:'bold', color:'black', fontSize:'18px'}}>
            <p class="highlight">
        <strong>"Our tailored facilities are designed for efficient processing and crafting of wood."</strong>
    </p></div>

    <div class="section">
        <h6><strong>STOCK YARD</strong></h6>
        <p>
            Our stockyard efficiently stores and organizes wood materials until needed for manufacturing, ensuring availability for production and facilitating inventory management and quality control processes.
        </p>
       
    </div>

    <div class="section">
        <h6><strong>SAW MILL</strong></h6>
        <p>
            We transform raw timber into exquisite pieces that tell stories of craftsmanship and quality. With precision machinery and skilled artisans, we take pride in every cut, ensuring each piece meets our exacting standards.
        </p>
       
    </div>

    <div class="section">
        <h6><strong>WOOD TREATING UNIT</strong></h6>
        <p>
            In our Wooden Chemical Treatment Plant, wood is stacked within chambers and immersed with a potent combination of Borax and Boric Acid. Through the application of pressure pumps, these chemicals penetrate the wood's pores, effectively resisting termites and wood decay for years to come.
        </p>
     
    </div>

    <div class="section">
        <h6><strong>WOOD SEASONING UNIT</strong></h6>
        <p>
            The treated wood is stacked within chambers and subjected to hot air, ensuring that the moisture content is reduced to below 15%. This meticulous drying process prevents shrinkage or expansion of the wood while also creating an environment inhospitable to termites. Our comprehensive treatment and seasoning processes guarantee the longevity and durability of our wood products.
        </p>
     
    </div>

    <div class="section">
        <h6><strong>CARPENTRY WORKSHOP</strong></h6>
        <p>
            From custom designs to traditional woodworking techniques, our workshop is fully equipped to handle a wide range of projects. This blend of versatility and expertise results in timeless pieces that stand the test of time. From concept to creation, we take immense pride in transforming wood into functional works of art that enhance any space.
        </p>
        
    </div>

    <div class="section">
        <h6><strong>DESIGNING TEAM</strong></h6>
        <p>
            Our Designing Team serves as the creative powerhouse behind every project we undertake. It consists of talented architects, designers, and craftsmen who collaborate to transform your ideas into stunning realities. With a keen eye for detail and a commitment to innovation, our team ensures that each design reflects your unique style and meets your specific needs.
        </p>
      
    </div>

    <div class="section">
        <h6><strong>PAINT BOOTH</strong></h6>
        <p>
            The paint booth serves as a crucial component of our product's finishing process. Within this controlled environment, our skilled craftsmen meticulously apply paints, stains, and finishes to our wood products. This ensures that our products not only look beautiful but also maintain their durability and longevity for years to come.
        </p>
    </div>

        </div>
    );
};

export default MyStory;
